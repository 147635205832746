<template>
  <div class="overlay sidebar game-about">
    <div
      class="overlay-bg"
      @click="closeModal"
    ></div>
    <div class="overlay-inner">
      <h2 class="overlay-title">{{ $t('about-title') }}</h2>
      <div class="overlay-content">
        <h3>{{ $t('about-tips-title') }}</h3>
        <p>{{ $t('about-tips-content') }}</p>

        <h3>{{ $t('about-shortcuts-title') }}</h3>
        <p v-html="$t('about-shortcuts-intro')"></p>
        <dl class="shortcut">
          <dt>N</dt>
          <dd>{{ $t('about-shortcuts-n') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>M</dt>
          <dd>{{ $t('about-shortcuts-m') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>S</dt>
          <dd>{{ $t('about-shortcuts-s') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>T</dt>
          <dd>{{ $t('about-shortcuts-t') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>A</dt>
          <dd>{{ $t('about-shortcuts-a') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>U</dt>
          <dd>{{ $t('about-shortcuts-u') }}</dd>
        </dl>

        <dl class="shortcut">
          <dt>S</dt>
          <dd>{{ $t('about-shortcuts-s2') }}</dd>
        </dl>

        <!-- <dl class="shortcut">
          <dt>C</dt>
          <dd>{{ $t('about-shortcuts-c') }}</dd>
        </dl> -->

        <dl class="shortcut">
          <dt>Esc</dt>
          <dd>{{ $t('about-shortcuts-esc') }}</dd>
        </dl>
      </div>
      <div class="overlay-actions">
        <div
          class="btn"
          @click="closeModal"
          @shortkey="closeModal"
          v-html="$t('panel-btn-close')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
  },
}
</script>

import { createStore } from 'vuex'
import moduleModes from './collections'

export default createStore({
  state: {
    // saved in localStorage
    settings: {
      lang: null,
      mode: null,
      nbRows: null,
      nbCols: null,
      noAnimations: false,
      fluidLayout: false,
      fluidNbTiles: false,
    },
    playerStats: [],

    // not saved in localStorage
    defaultSettings: {
      lang: null,
      mode: 'travels',
      nbRows: 4,
      nbCols: 4,
      noAnimations: false,
      fluidLayout: true,
      fluidNbTiles: 20,
    },
    tempSettings: {},
    localDataDeleted: false,
  },

  getters: {
    checkLocalData: function () {
      let data = JSON.parse(localStorage.getItem('memory'))
      return data ? true : false
    },
    checkLocalStats: function () {
      let data = JSON.parse(localStorage.getItem('memory'))
      return data && data.playerStats.length
    },
  },

  mutations: {
    setTilesFiles (state, payload) {
      if (! state.modes[payload.key]) {
        state.modes[payload.key] = []
      }
      state.modes[payload.key].files = payload.files
    },
    addStats (state, s) {
      state.playerStats.push(s)
    },
    deleteStats: function (state) {
      state.playerStats = []
    },

    setNbCols (state, value) {
      state.settings.nbCols = value
    },
    setNbRows (state, value) {
      state.settings.nbRows = value
    },

    initLang (state, i18n) {
      state.defaultSettings.lang = i18n.locale || i18n.fallbackLocale
    },
    setLang (state) {
      if (! state.settings.lang) {
        state.settings.lang = state.defaultSettings.lang
      }
    },

    // settings management
    resetDefaultSettings (state) {
      state.settings = {
        lang:         state.defaultSettings.lang,
        mode:         state.defaultSettings.mode,
        nbRows:       state.defaultSettings.nbRows,
        nbCols:       state.defaultSettings.nbCols,
        noAnimations: state.defaultSettings.noAnimations,
        fluidLayout:  state.defaultSettings.fluidLayout,
        fluidNbTiles: state.defaultSettings.fluidNbTiles,
      }
    },
    setTempSettingsAsDefault (state) {
      state.tempSettings = {
        hasChanges: true,
        lang:         state.defaultSettings.lang,
        mode:         state.defaultSettings.mode,
        nbRows:       state.defaultSettings.nbRows,
        nbCols:       state.defaultSettings.nbCols,
        noAnimations: state.defaultSettings.noAnimations,
        fluidLayout:  state.defaultSettings.fluidLayout,
        fluidNbTiles: state.defaultSettings.fluidNbTiles,
      }
    },
    saveTempSettings (state, tempSettings) {
      if (
        tempSettings.lang         !== state.settings.lang         ||
        tempSettings.mode         !== state.settings.mode         ||
        tempSettings.nbRows       !== state.settings.nbRows       ||
        tempSettings.nbCols       !== state.settings.nbCols       ||
        tempSettings.noAnimations !== state.settings.noAnimations ||
        tempSettings.fluidLayout  !== state.settings.fluidLayout  ||
        tempSettings.fluidNbTiles !== state.settings.fluidNbTiles
      ) {
        state.tempSettings = {
          hasChanges: true,
          lang:         tempSettings.lang,
          mode:         tempSettings.mode,
          nbRows:       tempSettings.nbRows,
          nbCols:       tempSettings.nbCols,
          noAnimations: tempSettings.noAnimations,
          fluidLayout:  tempSettings.fluidLayout,
          fluidNbTiles: tempSettings.fluidNbTiles,
        }
      }
    },
    applyTempSettings (state) {
      state.settings = {
        lang:         state.tempSettings.lang,
        mode:         state.tempSettings.mode,
        nbRows:       state.tempSettings.nbRows,
        nbCols:       state.tempSettings.nbCols,
        noAnimations: state.tempSettings.noAnimations,
        fluidLayout:  state.tempSettings.fluidLayout,
        fluidNbTiles: state.tempSettings.fluidNbTiles,
      }
    },
    resetTempSettings (state) {
      state.tempSettings = {
        hasChanges: false,
        lang: null,
        mode: null,
        nbRows: null,
        nbCols: null,
        noAnimations: false,
        fluidLayout: false,
        fluidNbTiles: null,
      }
    },

    // localStorage
    saveLocalData: function (state) {
      let data = {
        settings:    state.settings,
        playerStats: state.playerStats,
      };
      localStorage.setItem('memory', JSON.stringify(data))
    },
    getLocalData: function (state) {
      let data = JSON.parse(localStorage.getItem('memory'))
      if (data) {
        state.settings    = data.settings
        state.playerStats = data.playerStats
      }
      if (!state.modes[state.settings.mode]) {
        state.settings.mode = 'all'
      }
    },
  },

  actions: {
    setTilesLists (context) {
      let tiles = require.context(
        '@/assets/tiles',
        true,
        /^.*.(jpg|png|gif)$/
      ).keys()

      tiles = tiles.map(x => x.slice('./'.length))
      tiles = tiles.filter(x => x !== 'blank.jpg')
      let all = []

      for (const key in context.state.modes) {
        let folder = key + '/'
        let r1 = tiles.filter(path => path.indexOf(folder) === 0)
        this.commit('setTilesFiles', {key: key, files: r1})
        all.push(...r1)
      }

      // "all"
      if (context.state.modes.all) {
        this.commit('setTilesFiles', {key: 'all', files: all})
      }
    },
    setLanguage (context, lang) {
      context.state.settings.lang = lang
      this.commit('saveLocalData')
    },
    applySettings (context) {
      this.commit('applyTempSettings')
      this.commit('resetTempSettings')
      if (! context.state.localDataDeleted) {
        this.commit('saveLocalData')
      } else {
        context.state.localDataDeleted = false
      }
    },
    saveStats (_, s) {
      this.commit('addStats', s)
      this.commit('saveLocalData')
    },
    deleteStats () {
      this.commit('deleteStats')
      this.commit('saveLocalData')
    },
    deleteLocalData: function (context) {
      localStorage.removeItem('memory')
      this.commit('setTempSettingsAsDefault')
      context.state.localDataDeleted = true
    },
  },

  modules: {
    modes: moduleModes,
  },
})

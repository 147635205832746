<template>
  <div class="app" :class="appClass">
    <h1 class="app-title"><span>{{ appName }}</span>Memory</h1>
    <Game />
  </div>
</template>

<script>
import Game from './components/Game.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'AppRoot',
  components: {
    Game,
  },
  computed: {
    appName () {
      let name = this.modes[this.settings.mode][this.settings.lang].appName
      return name ? name : ''
    },
    appClass () {
      let c = []
      if (this.settings.noAnimations) {
        c.push('no-animations')
      }
      return c
    },
    ...mapState({
      modes: 'modes',
      settings: 'settings',
    }),
  },
  methods: {
    ...mapMutations([
      'initLang',
      'setLang',
      'getLocalData',
      'resetDefaultSettings',
    ]),
    ...mapActions([
      'setTilesLists',
    ]),
  },
  beforeMount () {
    this.initLang(this.$i18n)
    this.resetDefaultSettings()
    this.getLocalData()
    this.setLang()
    this.setTilesLists()
    let lang = this.settings.lang
    if (lang && this.$i18n.availableLocales.includes(lang)) {
      this.$i18n.locale = lang
    }
  }
}
</script>

<style lang="scss">
  @import './assets/app.scss';
</style>

<template>
  <div class="global-shortcuts">
    <div
      @shortkey="reInitGame"
      v-shortkey="['n']"
    ></div>
    <div
      @shortkey="openModal('settings')"
      v-shortkey="['s']"
    ></div>
    <div
      @shortkey="openModal('stats')"
      v-shortkey="['t']"
    ></div>
    <div
      v-if="isFinished"
      @shortkey="openModal('summary')"
      v-shortkey="['u']"
    ></div>
    <div
      @shortkey="openModal('about')"
      v-shortkey="['a']"
    ></div>
    <div
      @shortkey="toggleFullScreen"
      v-shortkey="['f']"
    ></div>
  </div>

  <div
    class="show-menu"
    @click="toggleMenu"
    @shortkey="toggleMenu"
    v-shortkey="['m']"
  >
    <span v-html="$t('menu')"></span>
  </div>
  <transition name="fade">
    <div
      class="close-menu"
      v-if="menuVisible"
      @click="closeMenu"
    ></div>
  </transition>

  <transition name="fade">
    <div
      class="menu"
      v-if="menuVisible"
    >
      <div
        class="menu-item"
        @click="reInitGame"
        v-html="$t('menu-newgame')"
      ></div>
      <div
        class="menu-item"
        @click="openModal('settings')"
        v-html="$t('menu-settings')"
      ></div>
      <div
        class="menu-item"
        @click="openModal('stats')"
        v-html="$t('menu-stats')"
      ></div>
      <div
        class="menu-item"
        v-if="isFinished"
        @click="openModal('summary')"
        v-html="$t('menu-summary')"
      ></div>
      <div
        class="menu-item"
        @click="openModal('about')"
        v-html="$t('menu-about')"
      ></div>
      <div
        class="menu-item"
        @click="toggleFullScreen"
        v-html="$t('menu-fullscreen')"
      ></div>

      <div class="menu-lang">
        <ul>
          <li
            v-for="(lang, i) in $i18n.availableLocales"
            :key="`lang${i}`"
            :value="lang"
            :class="$i18n.locale == lang ? 'active' : ''"
            @click="setLang(lang)"
          >{{ lang }}</li>
        </ul>
      </div>

    </div>
  </transition>

  <div
    class="game"
    :class="gameClass"
    @shortkey="pressEsc"
    v-shortkey="['esc']"
  >
    <div
      class="tiles-wrapper"
      :class="wrapperClass"
    >
      <div
        id="board"
        class="tiles"
        :class="tilesClass"
        :style="gridCss"
      >
        <Tile
          v-for="(tile, i) in tiles"
          :key="i"
          :tile="tile"
          :tileWidth="tileWidth"
          @click="showTile(tile)"
          @setFs="setFs"
        />
        <transition name="fade">
          <div
            class="tile-fs"
            v-if="allowFs && fsTile && fsTile.name"
            @click="unsetFs"
          >
            <div
              class="tile-fs-inner"
              :style="{'background-image': 'url('+fsUrl+')',}"
            ></div>
            <div
              class="fs-nav prev"
              @click.stop="setNextFs('prev')"
              @shortkey="setNextFs('prev')"
              v-shortkey="['arrowleft']"
            ></div>
            <div
              class="fs-nav next"
              @click.stop="setNextFs('next')"
              @shortkey="setNextFs('next')"
              v-shortkey="['arrowright']"
            ></div>
          </div>
        </transition>
      </div>
    </div>
  </div>

  <transition name="slide">
    <Settings
      v-if="openedModal == 'settings'"
      :autoPlayActive="autoPlayActive"
      :autoPlayLoop="autoPlayLoop"
      @toggleAutoPlay="toggleAutoPlay"
      @toggleAutoPlayLoop="toggleAutoPlayLoop"
      @closeSettings="closeSettings"
    />
  </transition>

  <transition name="slide">
    <About
      v-if="openedModal == 'about'"
      @closeModal="closeModal"
    />
  </transition>

  <transition name="slide">
    <Stats
      v-if="openedModal == 'stats'"
      @openModal="openModal"
      @closeModal="closeModal"
    />
  </transition>

  <transition name="fade">
    <Summary
      v-if="openedModal == 'summary'"
      :timer="timer"
      :nbTries="nbTries"
      :nbFound="nbFound"
      @closeModal="closeModal"
      @reInitGame="reInitGame"
    />
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Tile from './Tile.vue'
import Stats from './Stats.vue'
import About from './About.vue'
import Summary from './Summary.vue'
import Settings from './Settings.vue'

export default {
  name: 'GameView',
  components: {
    Tile,
    About,
    Summary,
    Settings,
    Stats,
  },
  data () {return {
    // reset by this.initData()
    allTiles: [],
    tiles: [], // tiles used for the game
    nbTilesShown: 0,
    tilesLocked: false,
    nbTries: 0,
    nbFound: 0,
    timer: {
      start: null,
      end: null,
    },
    fsTile: null,
    allowFs: false,
    autoPlayIndex: 0,
    autoPlayMemory: [],
    autoPlayTimeout: null,

    // NOT reset by this.initData()
    wrapperClass: '',
    tilesClass: '',
    hideTilesTimeout: null,
    openedModal: '',
    resetting: 0,
    menuVisible: false,
    boardWidth: 0,
    boardHeight: 0,
    tileWidth: 0,
    tileWidthTest: 0,
    autoPlayActive: false,
    autoPlayLoop: false,
  }},

  computed: {
    fsUrl () {
      if (this.fsTile && this.fsTile.name) {
        return require('@/assets/tiles/' + this.fsTile.name)
      } else {
        return require('@/assets/tiles/blank.jpg')
      }
    },
    isFinished () {
      return this.nbFound == this.tiles.length / 2
    },
    gameClass () {
      let c = []
      if (this.settings.fluidLayout) {
        c.push('fluid-layout')
      }
      if (this.isFinished) {
        c.push('is-finished')
      }
      if (this.allowFs) {
        c.push('allow-fs')
      }
      if (this.openedModal) {
        c.push('opened-' + this.openedModal)
      }
      return c
    },
    gridCss () {
      let css = {
        'grid-template-rows':    'repeat('+this.settings.nbRows+', 1fr)',
        'grid-template-columns': 'repeat('+this.settings.nbCols+', 1fr)',
      }
      return css
    },
    nbTiles () {
      if (this.settings.fluidLayout) {
        return this.settings.fluidNbTiles
      } else {
        let nb = this.settings.nbRows * this.settings.nbCols
        if (nb % 2 == 1) {
          nb--
        }
        return nb
      }
    },
    ...mapState([
      'modes',
      'settings',
      'tempSettings',
      'localDataDeleted',
    ]),
  },

  methods: {
    initData () {
      this.allTiles = this.modes[this.settings.mode].files
      this.tiles = []
      this.nbTilesShown = 0
      this.tilesLocked = false
      this.nbTries = 0
      this.nbFound = 0
      this.resetting = 0
      this.timer = {
        start: null,
        end: null,
      }
      this.fsTile = null
      this.allowFs = false
      this.autoPlayIndex = 0
      clearTimeout(this.autoPlayTimeout)
      this.autoPlayTimeout = null
    },
    initGame () {
      if (this.tempSettings.hasChanges) {
        this.applySettings()
      }
      this.initData()
      this.checkFluidLayout()
      this.tilesClass = ''
      this.wrapperClass = ''

      let randomTiles = this.allTiles
      // randomize array in order to draw random tiles
      for (let j, x, i = randomTiles.length; i; j = parseInt(Math.random() * i), x = randomTiles[--i], randomTiles[i] = randomTiles[j], randomTiles[j] = x);

      // draw random tiles (2x each)
      let tilesTemp = []
      for (let i = 1; i <= this.nbTiles/2; i++) {
        tilesTemp.push(randomTiles[i])
        tilesTemp.push(randomTiles[i])
      }
      // randomize those tiles
      for (let j, x, i = tilesTemp.length; i; j = parseInt(Math.random() * i), x = tilesTemp[--i], tilesTemp[i] = tilesTemp[j], tilesTemp[j] = x);

      for (let i = 0; i < tilesTemp.length; i++) {
        this.tiles.push({index: i, name: tilesTemp[i], status: 0})
        // status: 0 = hidden; 1 = visible; 2 = found; -1 = unflipped (re-hidden)
      }

      if (this.autoPlayActive) {
        this.restartAutoPlay()
      }

      setTimeout(() => {
        this.checkFluidLayout()
      }, 10)
    },
    reInitGame () {
      this.closeMenu()
      if (this.settings.noAnimations) {
        this.initGame()
      } else if (this.nbFound || this.nbTilesShown) {
        this.wrapperClass = 'resetting'
        this.tilesClass = 'faster'
        this.unflipAllTiles()
      } else {
        this.initGame()
      }
    },
    unflipAllTiles () {
      let nbTiles = this.tiles.length
      if (this.resetting == nbTiles) {
        setTimeout(() => {
          this.initGame()
        }, 500);
        return
      }

      let animationDelay = Math.round(1000 / (nbTiles * 2))

      this.tiles[this.resetting].status = -1
      this.resetting++

      setTimeout(() => {
        this.unflipAllTiles()
      }, animationDelay)
    },


    setStats () {
      let s = {
        mode: this.settings.mode,
        grid: [this.settings.nbRows, this.settings.nbCols],
        tiles: this.tiles.length,
        date: this.timer.start,
        duration: this.timer.duration,
        tries: this.nbTries,
        fluidLayout: this.settings.fluidLayout,
        fluidNbTiles: this.settings.fluidNbTiles,
      }
      this.saveStats(s)
    },


    compareTiles () {
      let tile1 = null;
      let tile2 = null;

      this.tilesLocked = true
      this.nbTries++

      for (let i = 0; i < this.tiles.length; i++) {
        const tile = this.tiles[i]
        if (tile.status === 1) {
          if (tile1 === null) {
            tile1 = tile
          } else {
            tile2 = tile
            break
          }
        }
      }
      if (tile1.name === tile2.name) {
        this.tiles[tile1.index].status = 2
        this.tiles[tile2.index].status = 2
        this.nbFound++
        this.hideTiles()
      } else {
        this.hideTilesTimeout = setTimeout(() => {
          this.hideTiles()
        }, 2000)
      }
    },
    hideTiles (quickly) {
      if (quickly) {
        this.tilesClass = 'faster'
      }
      clearTimeout(this.hideTilesTimeout)
      for (let i = 0; i < this.tiles.length; i++) {
        if (this.tiles[i].status === 1) {
          this.tiles[i].status = -1
        }
      }

      setTimeout(() => {
        this.tilesClass = ''
        for (let i = 0; i < this.tiles.length; i++) {
          if (this.tiles[i].status === -1) {
            this.tiles[i].status = 0
          }
        }
      }, 1000);

      this.nbTilesShown = 0
      this.tilesLocked = false
      if (this.isFinished) {
        this.timer.end = Date.now()
        this.timer.duration = this.timer.end - this.timer.start
        if (this.autoPlayActive) {
          this.autoPlayActive = false
          // let's not save those stats...
        } else {
          this.setStats()
        }
        setTimeout(() => {
          this.openModal('summary')
          this.allowFs = true
        }, 500)
      }
    },
    showTile (tile) {
      if (this.isFinished) {
        this.setFs(tile)
      }
      if (this.tiles[tile.index].status >= 1) {
        return
      }
      if (this.tilesLocked) {
        this.hideTiles(true)
        setTimeout(() => {
          this.showTile(tile)
        }, 400)
        return
      }
      if (this.nbTilesShown === 0 && this.nbTries === 0) {
        this.timer.start = Date.now()
      }
      this.tiles[tile.index].status = 1
      this.nbTilesShown++
      if (this.nbTilesShown == 2) {
        this.compareTiles()
      }
    },


    toggleAutoPlayLoop () {
      this.autoPlayLoop = ! this.autoPlayLoop
    },
    toggleAutoPlay (loop) {
      if (this.autoPlayActive) {
        this.stopAutoPlay()
      } else {
        console.log(loop)
        // this.autoPlayLoop = loop ? true : false
        this.startAutoPlay()
      }
    },
    restartAutoPlay () {
      this.stopAutoPlay()
      this.startAutoPlay()
    },
    startAutoPlay () {
      this.autoPlayActive = true
      this.autoPlayIndex = 0
      this.autoPlayMemory = []
      for (let i = 0; i < this.tiles.length; i++) {
        this.autoPlayMemory[i] = undefined
      }
      if (this.isFinished) {
        this.reInitGame()
      } else {
        this.autoPlay()
      }
    },
    stopAutoPlay () {
      clearTimeout(this.autoPlayTimeout)
      this.autoPlayTimeout = null
      this.autoPlayMemory = []
      this.autoPlayActive = false
    },
    autoPlay (iRemembered) {
      if (this.isFinished) {
        this.stopAutoPlay()
        if (this.autoPlayLoop) {
          setTimeout(() => {
            this.closeModal()
            this.startAutoPlay()
          }, 5*1000);
        }
        return
      }
      if (! this.autoPlayActive) {return}

      let unfoundTiles = this.tiles.filter(x => x.status < 1)
      let tile = null

      // algo 1: randomly selected
        // tile = unfoundTiles[Math.floor(Math.random() * unfoundTiles.length)]

      // algo 2: perfect memory
      // if a pair has already been memorized, start with the first one
        for (let i = 0; i < this.autoPlayMemory.length; i++) {
          const name = this.autoPlayMemory[i]
          for (let j = 0; j < this.autoPlayMemory.length; j++) {
            const name2 = this.autoPlayMemory[j]
            if (name && name === name2 && i !== j && this.tiles[i].status < 1) {
              this.showTile(this.tiles[i])
              setTimeout(() => {
                this.autoPlay(true)
              }, 800)
              return
            }
          }
        }

      // if a pair has'nt already been identified...

        // 1. pick a random tile among all the tiles that we know we haven't flipped yet
        let allUnflipped = this.autoPlayMemory.filter(x => x === undefined)
        let allUnflippedNb = allUnflipped.length
        if (allUnflippedNb > 2) {
          let random = Math.round(Math.random() * allUnflippedNb)
          for (let i = 0; i < this.autoPlayMemory.length; i++) {
            if (this.autoPlayMemory[i] === undefined) {
              random--
            }
            if (random <= 0) {
              tile = this.tiles[i]
              break
            }
          }
        }

        // 2. if this doesn't give a result,
        // let's just flip the first one that we know we haven't flipped yet
        if (! tile) {
          let firstUnflipped = -1
          for (const name of this.autoPlayMemory) {
            firstUnflipped++
            if (name === undefined && this.tiles[firstUnflipped].status < 1) {
              tile = this.tiles[firstUnflipped]
              break
            }
          }
        }

        // 3. still no result, i.e. no unflipped-yet tile found; let's go sequentially
        if (! tile) {
          if (this.autoPlayIndex >= unfoundTiles.length) {
            this.autoPlayIndex = 0
          }
          tile = unfoundTiles[this.autoPlayIndex]
          if (this.nbTilesShown === 1) {
            if (! iRemembered) {
              this.autoPlayIndex += 2
            }
          }
        }

        // 4. as a security... (get a random tile)
        if (! tile) {
          tile = unfoundTiles[Math.floor(Math.random() * unfoundTiles.length)]
        }

      // END algos

      this.showTile(tile)
      this.autoPlayMemory[tile.index] = tile.name

      if (this.nbTilesShown === 1) {
        for (let i = 0; i < this.tiles.length; i++) {
          const name = this.autoPlayMemory[i]
          if (name == tile.name && i !== tile.index) {
            setTimeout(() => {
              this.showTile(this.tiles[i])
            }, 800)
            setTimeout(() => {
              this.autoPlay()
            }, 2000)
            return
          }
        }
      }

      let t = 3000
      if (this.nbTilesShown === 1) {
        t = 800
      }
      this.autoPlayTimeout = setTimeout(() => {
        this.autoPlay()
      }, t)
    },


    setFs (tile) {
      if (this.allowFs) {
        this.fsTile = tile
      }
    },
    setNextFs (dir) {
      let index = this.fsTile.index
      if (dir == 'next') {
        index++
        if (index >= this.tiles.length) {
          index = 0
        }
      } else {
        index--
        if (index < 0) {
          index = this.tiles.length-1
        }
      }
      let nextTile = this.tiles[index]
      let skip = false
      if (nextTile.name == this.fsTile.name) {
        skip = true
      }
      this.setFs(nextTile)
      if (skip) {
        this.setNextFs(dir)
      }
    },
    unsetFs () {
      this.fsTile = null
    },


    toggleMenu () {
      this.menuVisible = !this.menuVisible
      if (this.menuVisible) {
        this.closeModal()
      }
    },
    closeMenu () {
      this.menuVisible = false
    },


    pressEsc () {
      this.unsetFs()
      this.closeMenu()
      this.closeModal()
    },
    openModal (name) {
      this.openedModal = name
      this.closeMenu()
    },
    closeModal () {
      this.openedModal = ''
    },


    closeSettings (reinitBoard) {
      this.closeModal()
      if (reinitBoard) {
        this.reInitGame()
      } else if (this.tempSettings.hasChanges) {
        this.applySettings()
      }
    },
    setLang (lang) {
      this.$i18n.locale = lang
      this.setLanguage(lang)
    },


    testFluidLayout () {
      // with this size of image (%), what size of a grid do we build
      // and how many full images can fit in this grid?
      let imgPx = this.boardWidth * (this.tileWidthTest / 100)
      let nbCols = Math.floor(this.boardWidth / imgPx)
      let nbRows = Math.floor(this.boardHeight / imgPx)
      let total = nbCols * nbRows

      if (total >= this.settings.fluidNbTiles) {
        this.tileWidthTest += 0.1
        this.testFluidLayout()
      } else {
        // we just passed the limit, now the grid is too small;
        // let's go back one iteration and wrap things up
        this.tileWidthTest -= 0.1
      }
    },
    checkFluidLayout () {
      if (! this.settings.fluidLayout) {return}
      let board  = document.getElementById('board')
      this.boardWidth  = Math.floor(board.offsetWidth)
      this.boardHeight = Math.floor(board.offsetHeight)

      let startingTilePc = 100 / this.settings.fluidNbTiles

      this.tileWidthTest = startingTilePc
      this.testFluidLayout()
      this.tileWidth = this.tileWidthTest
    },


    toggleFullScreen () {
      console.log('toggleFullScreen')
      if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
      this.closeMenu()
    },


    ...mapActions([
      'saveStats',
      'applySettings',
      'setLanguage',
    ]),
  },
  mounted () {
    this.initGame()
    // this.toggleFullScreen()
    window.addEventListener('resize', () => {this.checkFluidLayout()})
  },
}
</script>

<template>
    <div class="overlay game-summary">
      <div
        class="overlay-bg"
        @click="closeModal"
      ></div>
      <div class="overlay-inner">
        <h2 class="overlay-title">{{ $t('summary-congrats') }}</h2>
        <div class="overlay-content">
          <p>
            <span v-html="$t('summary-content-01', {nbFound, nbTries, duration})"></span><br>
            <template v-if="bestTime"><span v-html="$t('summary-content-02a')"></span>
              <template v-if="! settings.fluidLayout"><span v-html="$t('summary-content-02b', {nbRows: settings.nbRows, nbCols: settings.nbCols})"></span><br></template>
              <template v-if="settings.fluidLayout"><span v-html="$t('summary-content-02c', {nbTiles: settings.fluidNbTiles})"></span><br></template>
            </template>
            <span v-html="$t('summary-content-03', {time: efficiency.time})"></span></p>
          <p><span v-html="$t('summary-content-04', {triesPc: '<strong>'+efficiency.tries+'%</strong>'})"></span><br>
            <span class="eval" :class="evaluation.class">{{ evaluation.txt }}</span>
            <template v-if="bestTries"><br><span v-html="$t('summary-content-05a')"></span>
              <template v-if="! settings.fluidLayout"><span v-html="$t('summary-content-05b', {nbRows: settings.nbRows, nbCols: settings.nbCols})"></span></template>
              <template v-if="settings.fluidLayout"><span v-html="$t('summary-content-05c', {nbTiles: settings.fluidNbTiles})"></span></template>
            </template>
          </p>
          <p style="margin-top: 1em;">{{ $t('summary-start-again') }}</p>
        </div>
        <div class="overlay-actions">
          <div
            class="btn"
            @click="reInitGame"
            @shortkey="reInitGame"
            v-shortkey="['y']"
            v-html="$t('panel-btn-yes')"
          ></div>
          <div
            class="btn"
            @click="closeModal"
            @shortkey="closeModal"
            v-shortkey="['esc']"
            v-html="$t('panel-btn-no')"
          ></div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SummaryView',
  props: {
    timer: Object,
    nbTries: Number,
    nbFound: Number,
  },
  data () {return {
    bestTries: false,
    bestTime: false,
  }},
  computed: {
    duration () {
      let time = this.timer.duration
      let mins = parseInt(time / (1000 * 60))
      let secs = parseInt(time / 1000) - (mins * 60)
      let ms   = time - ((mins * 60 * 1000) + (secs * 1000))
      let msRounded = Math.round(ms / 10) // rounded to the 100th of a second

      let txt = ''
      if (mins > 0) {
        txt += mins + this.$t('summary-content-01-minutes')
      }

      txt += secs
      if (msRounded > 0) {
        txt += '.' + msRounded
      }
      txt += this.$t('summary-content-01-seconds')

      return txt
    },
    efficiency () {
      let eff = {}
      eff.tries = ((this.nbFound / this.nbTries) * 100).toFixed(2)
      eff.time = ((this.timer.duration / 1000) / (this.nbFound * 2)).toFixed(2)
      return eff
    },
    evaluation () {
      // note: should take the number of tiles into account? and/or the timer?
      let r = {
        txt: '',
        class: ''
      }
      let eff = this.efficiency.tries
      if      (eff >  95) {r.txt = this.$t('summary-eval-great1')   ; r.class='eval-great'}
      else if (eff >  65) {r.txt = this.$t('summary-eval-great2')   ; r.class='eval-great'}
      else if (eff >  55) {r.txt = this.$t('summary-eval-good1')    ; r.class='eval-good'}
      else if (eff >  50) {r.txt = this.$t('summary-eval-good2')    ; r.class='eval-good'}
      else if (eff == 50) {r.txt = this.$t('summary-eval-avg')      ; r.class='eval-avg'}
      else if (eff >  45) {r.txt = this.$t('summary-eval-bad1')     ; r.class='eval-bad'}
      else if (eff >  35) {r.txt = this.$t('summary-eval-bad2')     ; r.class='eval-bad'}
      else                {r.txt = this.$t('summary-eval-horrible') ; r.class='eval-horrible'}
      return r
    },
    ...mapState({
      stats: 'playerStats',
      settings: 'settings',
    }),
  },
  methods: {
    checkBest () {
      let temp
      if (this.settings.fluidLayout) {
        temp = this.stats.filter(x => x.mode == this.settings.mode && x.fluidNbTiles == this.settings.fluidNbTiles)
      } else {
        temp = this.stats.filter(x => x.mode == this.settings.mode && x.grid[0] == this.settings.nbRows && x.grid[1] == this.settings.nbCols)
      }
      let bestTime = temp.filter(x => x.duration < this.timer.duration)
      let bestTries = temp.filter(x => x.tries < this.nbTries)

      this.bestTime  = bestTime.length  === 0
      this.bestTries = bestTries.length === 0
    },
    closeModal () {
      this.$emit('closeModal')
    },
    reInitGame () {
      this.$emit('reInitGame')
      this.closeModal()
    },
  },
  mounted () {
    this.checkBest()
  },
}
</script>

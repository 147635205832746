/*

List all available collections of tiles below.

- The name of the property must be the same as the name of the folder that stores the images for that collection.
  - reserved name: "all" (see below)
- `name`: full-length name of the collection; visible in the settings and stats panels.
- `appName`: shorter name; visible in the app name on the main screen, immediately before the word "memory".

The "all" property is a "collect-all" special collection that (if present) will allow the player to choose to play with images from all collections at once.

*/

export default {state: {

  all: {
    en: {
      name: `All types of images`,
      appName: `all`,
    },
    fr: {
      name: `Tout type d'image`,
      appName: `omni`,
    },
  },

  travels: {
    en: {
      name: `Travels`,
      appName: `travel`,
    },
    fr: {
      name: `Voyages`,
      appName: `travel`,
    },
  },

  movies: {
    en: {
      name: `Movies`,
      appName: `film`,
    },
    fr: {
      name: `Films`,
      appName: `ciné`,
    },
  },

  games: {
    en: {
      name: `Games`,
      appName: `gamer`,
    },
    fr: {
      name: `Jeux`,
      appName: `ludo`,
    },
  },

  // male: {
  //   en: {
  //     name: `Massive muscles`,
  //     appName: `muscle`,
  //   },
  //   fr: {
  //     name: `Muscles énormes`,
  //     appName: `muscle`,
  //   },
  // },

  // female: {
  //   en: {
  //     name: `Huge boobs`,
  //     appName: `funbags`,
  //   },
  //   fr: {
  //     name: `Gros seins`,
  //     appName: `boobs`,
  //   },
  // },

  // art: {
  //   en: {
  //     name: `Drawings`,
  //     appName: `art`,
  //   },
  //   fr: {
  //     name: `Dessins`,
  //     appName: `art`,
  //   },
  // },

}}

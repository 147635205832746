export default {
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>M</ins>ENU"])},
  "menu-newgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>N</ins>ouvelle partie"])},
  "menu-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre<ins>s</ins>"])},
  "menu-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes s<ins>t</ins>atistiques"])},
  "menu-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rés<ins>u</ins>mé de la partie précédente"])},
  "menu-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>À</ins> propos"])},
  "menu-fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode plein écran"])},
  "panel-btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>S</ins>auver"])},
  "panel-btn-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "panel-btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "panel-btn-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "panel-btn-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "option-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "option-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "summary-congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations!"])},
  "summary-content-01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez trouvé <strong>", _interpolate(_named("nbFound")), " paires</strong> d'images<br>en <strong>", _interpolate(_named("nbTries")), " essais</strong> et <strong>", _interpolate(_named("duration")), "</strong>."])},
  "summary-content-01-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" minutes et "])},
  "summary-content-01-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" secondes"])},
  "summary-content-02a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est votre <span class='best'>meilleur temps</span> sur"])},
  "summary-content-02b": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" une grille de ", _interpolate(_named("nbRows")), "x", _interpolate(_named("nbCols")), " !"])},
  "summary-content-02c": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("nbTiles")), " images !"])},
  "summary-content-03": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(<strong>", _interpolate(_named("time")), " secondes</strong> par image en moyenne)."])},
  "summary-content-04": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("triesPc")), " de vos tentatives étaient correctes…"])},
  "summary-content-05a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est votre <span class='best'>meilleur score</span> sur"])},
  "summary-content-05b": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" une grille de ", _interpolate(_named("nbRows")), "x", _interpolate(_named("nbCols")), " !"])},
  "summary-content-05c": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("nbTiles")), " images !"])},
  "summary-eval-great1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel veinard!"])},
  "summary-eval-great2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressionnant!"])},
  "summary-eval-good1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Très joli!"])},
  "summary-eval-good2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieux que la moyenne."])},
  "summary-eval-avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parfaitement dans la moyenne."])},
  "summary-eval-bad1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins bien que la moyenne."])},
  "summary-eval-bad2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peut faire mieux."])},
  "summary-eval-horrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est pas votre jour, hein ?"])},
  "summary-start-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous lancer une autre partie ?"])},
  "stats-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques:"])},
  "stats-games-played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parties jouées"])},
  "stats-grid-n-images": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " images :"])},
  "stats-grid-n-games-recorded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " partie(s) enregistrées"])},
  "stats-grid-most-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la plus utilisée"])},
  "stats-grid-avg-tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nb. d'essai moyen/partie :"])},
  "stats-grid-avg-time-img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps moyen / image :"])},
  "stats-grid-avg-time-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps moyen / partie :"])},
  "stats-grid-best-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meilleur temps :"])},
  "stats-grid-best-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meilleur score :"])},
  "stats-grid-suffix-second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sec."])},
  "stats-grid-suffix-tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["essais"])},
  "stats-no-records-1a": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucune partie enregistrée", _interpolate(_named("withThisCol")), " pour l'instant."])},
  "stats-no-records-1b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" avec cette collection"])},
  "stats-no-records-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez l'essayer ?"])},
  "stats-no-records-3a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez changer de collection "])},
  "stats-no-records-3b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans les paramètres"])},
  "stats-no-records-3c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "settings-langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "settings-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
  "settings-collections-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images"])},
  "settings-max-grid-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de grille maximale :"])},
  "settings-max-images-board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximum d'images sur le plateau :"])},
  "settings-fluid-layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation fluide"])},
  "settings-fluid-nb-tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'images :"])},
  "settings-fluid-nb-rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de rangées :"])},
  "settings-fluid-nb-cols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de colonnes :"])},
  "settings-disable-animations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les animations"])},
  "settings-local-data-mgmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des données locales"])},
  "settings-local-data-delete-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les données"])},
  "settings-local-data-delete-all-confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr·e de vouloir supprimer vos paramètres de jeu ?\nCeci ne peut pas être annulé."])},
  "settings-local-data-delete-all-confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr·e de vouloir supprimer toutes les données locales (paramètres ET statistiques) ?\nCeci ne peut pas être annulé."])},
  "settings-local-data-delete-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les statistiques"])},
  "settings-local-data-delete-stats-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr·e de vouloir supprimer vos statistiques de jeu ?\nCeci ne peut pas être annulé. Vos paramètres de jeu seront conservées."])},
  "settings-local-data-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé."])},
  "settings-autoplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode démo (autoplay)"])},
  "settings-autoplay-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activé."])},
  "settings-autoplay-toggle-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "settings-autoplay-toggle-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter"])},
  "settings-autoplay-loop-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En boucle"])},
  "settings-autoplay-loop-toggle-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "settings-autoplay-loop-toggle-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "settings-autoplay-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les statistiques ne sont pas enregistrées en mode démo."])},
  "about-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
  "about-tips-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astuces"])},
  "about-tips-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand une partie est terminée, vous pouvez cliquez sur les images pour les afficher en plein écran (cliquez à nouveau pour fermer)."])},
  "about-shortcuts-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccourcis clavier"])},
  "about-shortcuts-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous disposez d'un clavier physique, vous pouvez utiliser les raccourcis suivants :"])},
  "about-shortcuts-n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle partie"])},
  "about-shortcuts-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir/fermer le menu"])},
  "about-shortcuts-s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir les paramètres"])},
  "about-shortcuts-t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir les statistiques"])},
  "about-shortcuts-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir À propos"])},
  "about-shortcuts-u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-afficher le résumé de la partie terminée"])},
  "about-shortcuts-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer/désactiver le mode plein écran"])},
  "about-shortcuts-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Sur l'écran Paramètres) Enregistrer les paramètres"])},
  "about-shortcuts-c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la popup"])},
  "about-shortcuts-esc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le panneau ou menu"])}
}
export default {
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>M</ins>ENU"])},
  "menu-newgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>N</ins>ew game"])},
  "menu-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>S</ins>ettings"])},
  "menu-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My s<ins>t</ins>ats"])},
  "menu-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous game s<ins>u</ins>mmary"])},
  "menu-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>A</ins>bout"])},
  "menu-fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>F</ins>ullscreen mode"])},
  "panel-btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>S</ins>ave"])},
  "panel-btn-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>C</ins>lose"])},
  "panel-btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "panel-btn-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ins>Y</ins>es"])},
  "panel-btn-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "option-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "option-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "summary-congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
  "summary-content-01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You found <strong>", _interpolate(_named("nbFound")), " pairs</strong> of images<br>in <strong>", _interpolate(_named("nbTries")), " tries</strong> and <strong>", _interpolate(_named("duration")), "</strong>."])},
  "summary-content-01-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" minutes and "])},
  "summary-content-01-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" seconds"])},
  "summary-content-02a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your <span class='best'>best time</span> yet on"])},
  "summary-content-02b": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" a ", _interpolate(_named("nbRows")), "x", _interpolate(_named("nbCols")), " grid!"])},
  "summary-content-02c": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("nbTiles")), " images!"])},
  "summary-content-03": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(<strong>", _interpolate(_named("time")), " seconds</strong> per image on average.)"])},
  "summary-content-04": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("triesPc")), " of your guesses were correct…"])},
  "summary-content-05a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your <span class='best'>best score</span> yet on"])},
  "summary-content-05b": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" a ", _interpolate(_named("nbRows")), "x", _interpolate(_named("nbCols")), " grid!"])},
  "summary-content-05c": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("nbTiles")), " images!"])},
  "summary-eval-great1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your lucky day!"])},
  "summary-eval-great2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressive!"])},
  "summary-eval-good1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quite nice!"])},
  "summary-eval-good2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above average."])},
  "summary-eval-avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfectly average."])},
  "summary-eval-bad1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below average."])},
  "summary-eval-bad2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could do better."])},
  "summary-eval-horrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not your day, huh?"])},
  "summary-start-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to start another game?"])},
  "stats-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats:"])},
  "stats-games-played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["games played"])},
  "stats-grid-n-images": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " images:"])},
  "stats-grid-n-games-recorded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " game(s) recorded"])},
  "stats-grid-most-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["most used"])},
  "stats-grid-avg-tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avg. nb of tries/game:"])},
  "stats-grid-avg-time-img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avg. time/image:"])},
  "stats-grid-avg-time-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avg. time/game:"])},
  "stats-grid-best-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["best time:"])},
  "stats-grid-best-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["best score:"])},
  "stats-grid-suffix-second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sec."])},
  "stats-grid-suffix-tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tries"])},
  "stats-no-records-1a": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No game recorded", _interpolate(_named("withThisCol")), " yet."])},
  "stats-no-records-1b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" with this collection"])},
  "stats-no-records-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanna try it out?"])},
  "stats-no-records-3a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can switch collection "])},
  "stats-no-records-3b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the settings"])},
  "stats-no-records-3c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "settings-langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "settings-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
  "settings-collections-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images"])},
  "settings-max-grid-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum grid dimension:"])},
  "settings-max-images-board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of images on the board:"])},
  "settings-fluid-layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluid layout"])},
  "settings-fluid-nb-tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of tiles:"])},
  "settings-fluid-nb-rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rows:"])},
  "settings-fluid-nb-cols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of columns:"])},
  "settings-disable-animations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable animations"])},
  "settings-local-data-mgmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local data management"])},
  "settings-local-data-delete-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all data"])},
  "settings-local-data-delete-all-confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sur you want to delete your game preferences?\nThis cannot be undone."])},
  "settings-local-data-delete-all-confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sur you want to delete all local data (preferences AND stats)?\nThis cannot be undone."])},
  "settings-local-data-delete-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete player stats"])},
  "settings-local-data-delete-stats-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sur you want to delete you playing stats?\nThis cannot be undone. Game preferences will be kept."])},
  "settings-local-data-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted."])},
  "settings-autoplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo mode (autoplay)"])},
  "settings-autoplay-toggle-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "settings-autoplay-toggle-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "settings-autoplay-loop-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looping"])},
  "settings-autoplay-loop-toggle-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "settings-autoplay-loop-toggle-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "settings-autoplay-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running."])},
  "settings-autoplay-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats are not saved in this mode."])},
  "about-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about-tips-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips"])},
  "about-tips-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When all tiles are uncovered, you can click on any of them to view it fullscreen (click again to close)."])},
  "about-shortcuts-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard shortcuts"])},
  "about-shortcuts-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your device has a physical keyboard, you can use the following shortcuts:"])},
  "about-shortcuts-n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New game"])},
  "about-shortcuts-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open/close Menu"])},
  "about-shortcuts-s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Settings"])},
  "about-shortcuts-t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Stats"])},
  "about-shortcuts-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open About"])},
  "about-shortcuts-u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen the summary of the finished game"])},
  "about-shortcuts-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle fullscreen mode"])},
  "about-shortcuts-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(On settings screen) Save settings"])},
  "about-shortcuts-c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close popup"])},
  "about-shortcuts-esc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close panel or menu"])}
}
<template>
  <div class="overlay sidebar game-settings">
    <div
      class="overlay-bg"
      @click="confirmAndClose"
    ></div>
    <div class="overlay-inner">
      <h2 class="overlay-title">{{ $t('settings-title') }}</h2>
      <div class="overlay-content">
        <dl>
          <dt v-if="nbLangs > 1">{{ $t('settings-langs') }}</dt>
          <dd v-if="nbLangs > 1">
            <ul class="options langs">
              <li
                v-for="(data, key) in langs"
                :key="key"
                :class="data == lang ? 'active' : ''"
                @click="updateLang(data)"
              >
                <div>{{ data }}</div>
              </li>
            </ul>
          </dd>

          <dt v-if="nbModes > 1">{{ $t('settings-collections') }}</dt>
          <dd v-if="nbModes > 1">
            <ul class="options">
              <li
                v-for="(data, key) in modes"
                :key="key"
                :class="key == mode ? 'active' : ''"
                @click="updateMode(key)"
              >
                <div>{{ data[lang].name }}</div>
                <small>{{ data.files.length }} {{ $t('settings-collections-images') }}</small>
              </li>
            </ul>
            <p
              class="max-grid-info"
              v-if="! fluidLayout"
            >{{ $t('settings-max-grid-dimension') }} <strong>{{ maxGrid }}</strong></p>
            <p
              class="max-grid-info"
              v-if="fluidLayout"
            >{{ $t('settings-max-images-board') }} <strong>{{ maxTilesEven }}</strong></p>
          </dd>

          <dt>{{ $t('settings-fluid-layout') }}</dt>
          <dd>
            <ul class="options">
              <li
                :class="fluidLayout ? 'active' : ''"
                @click="toggleFluidLayout"
              >
                <div>{{ $t('option-yes') }}</div>
              </li>
              <li
                :class="! fluidLayout ? 'active' : ''"
                @click="toggleFluidLayout"
              >
                <div>{{ $t('option-no') }}</div>
              </li>
            </ul>
          </dd>

          <template v-if="fluidLayout">
            <dt>{{ $t('settings-fluid-nb-tiles') }} <strong>
              <input type="number" min="4" :max="maxTilesEven" step="2" v-model="fluidNbTiles" @shortkey="confirmAndClose" v-shortkey="['enter']" onfocus="this.select()" onmouseup="return false">
            </strong></dt>
            <dd class="range-wrapper">
              <input type="range" min="4" :max="maxTilesEven" step="2" v-model="fluidNbTiles">
              <div
                v-if="false"
                class="range-ticks"
                :style="{'grid-template-columns': 'repeat('+(maxTilesEven-1)+', 1fr)', margin: '0 -' + 100/((maxTilesEven-1)*2) + '%'}"
              >
                <span
                  class="tick"
                  v-for="i in (maxGrid-1)"
                  :key="i"
                  @click="setNbRows(i+1)"
                >
                  <template v-if="i % 2 === 1">{{i+1}}</template>
                </span>
              </div>
            </dd>
          </template>

          <template v-if="! fluidLayout">
            <dt>{{ $t('settings-fluid-nb-rows') }} <strong>
              <input type="number" min="2" :max="maxGrid" step="1" v-model="nbRows" @change="updateGrid" @shortkey="confirmAndClose" v-shortkey="['enter']" onfocus="this.select()" onmouseup="return false">
            </strong></dt>
            <dd class="range-wrapper">
              <input type="range" min="2" :max="maxGrid" step="1" v-model="nbRows" @change="updateGrid">
              <div
                class="range-ticks"
                :style="{'grid-template-columns': 'repeat('+(maxGrid-1)+', 1fr)', margin: '0 -' + 100/((maxGrid-1)*2) + '%'}"
              >
                <span
                  class="tick"
                  v-for="i in (maxGrid-1)"
                  :key="i"
                  @click="setNbRows(i+1)"
                >{{i+1}}</span>
              </div>
            </dd>

            <dt>{{ $t('settings-fluid-nb-cols') }} <strong>
              <input type="number" min="2" :max="maxGrid" step="1" v-model="nbCols" @change="updateGrid" @shortkey="confirmAndClose" v-shortkey="['enter']" onfocus="this.select()" onmouseup="return false">
            </strong></dt>
            <dd class="range-wrapper">
              <input type="range" min="2" :max="maxGrid" step="1" v-model="nbCols" @change="updateGrid">
              <div
                class="range-ticks"
                :style="{'grid-template-columns': 'repeat('+(maxGrid-1)+', 1fr)', margin: '0 -' + 100/((maxGrid-1)*2) + '%'}"
              >
                <span
                  class="tick"
                  v-for="i in (maxGrid-1)"
                  :key="i"
                  @click="setNbCols(i+1)"
                >{{i+1}}</span>
              </div>
            </dd>
          </template>

          <dt>{{ $t('settings-disable-animations') }}</dt>
          <dd>
            <ul class="options">
              <li
                :class="noAnimations ? 'active' : ''"
                @click="toggleNoAnimations"
              >
                <div>{{ $t('option-yes') }}</div>
              </li>
              <li
                :class="! noAnimations ? 'active' : ''"
                @click="toggleNoAnimations"
              >
                <div>{{ $t('option-no') }}</div>
              </li>
            </ul>
          </dd>

          <dt v-if="hasLocalData">{{ $t('settings-local-data-mgmt') }}</dt>
          <dd v-if="hasLocalData" class="btns">
              <div
                class="btn"
                @click="deleteData"
                v-if="hasLocalData"
                :class="dataDeleted ? 'deleted' : ''"
              >
                {{ $t('settings-local-data-delete-all') }}
                <span class="confirm">{{ $t('settings-local-data-deleted') }}</span>
              </div>
              <div
                class="btn"
                @click="deletePlayerStats"
                v-if="hasLocalStats"
                :class="(statsDeleted || dataDeleted) ? 'deleted' : ''"
              >
                {{ $t('settings-local-data-delete-stats') }}
                <span class="confirm">{{ $t('settings-local-data-deleted') }}</span>
              </div>
          </dd>
          <dt>{{ $t('settings-autoplay') }}</dt>
          <dd class="btns">
              <div
                class="btn"
                @click="toggleAutoPlay"
                :class="[
                  autoPlayActive ? 'running' : '',
                ]"
              >
                <span v-if="! autoPlayActive">{{ $t('settings-autoplay-toggle-on') }}</span>
                <span v-if="autoPlayActive">{{ $t('settings-autoplay-toggle-off') }}</span>
                <span class="confirm">{{ $t('settings-autoplay-active') }}</span>
              </div>
              <div
                class="btn"
                @click="toggleAutoPlayLoop"
                :class="[
                  autoPlayActive ? 'running' : '',
                ]"
              >
                <div>{{ $t('settings-autoplay-loop-label') }}</div>
                <span v-if="autoPlayLoop">{{ $t('settings-autoplay-loop-toggle-on') }}</span>
                <span v-if="! autoPlayLoop">{{ $t('settings-autoplay-loop-toggle-off') }}</span>
              </div>
              <div class="note">{{ $t('settings-autoplay-note') }}</div>
          </dd>
        </dl>
      </div>
      <div class="overlay-actions">
        <div
          class="btn"
          @click="confirmAndClose"
          @shortkey="confirmAndClose"
          v-shortkey="['s']"
          v-html="$t('panel-btn-save')"
        ></div>
        <div
          class="btn"
          @click="cancelAndClose"
          @shortkey="cancelAndClose"
          v-html="$t('panel-btn-cancel')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'SettingsView',
  props: {
    autoPlayActive: Boolean,
    autoPlayLoop: Boolean,
  },
  data () {return {
    lang: null,
    mode: '',
    nbRows: 0,
    nbCols: 0,
    noAnimations: false,
    fluidLayout: false,
    fluidNbTiles: 0,
    dataDeleted: false,
    statsDeleted: false,
  }},

  computed: {
    langs () {
      return this.$i18n.availableLocales
    },
    nbLangs () {
      return this.$i18n.availableLocales.length
    },
    hasLocalData () {
      return this.checkLocalData
    },
    hasLocalStats () {
      return this.checkLocalStats
    },
    nbModes () {
      let nb = 0
      for (const mode in this.modes) {
        if (Object.hasOwnProperty.call(this.modes, mode)) {
          nb++
        }
      }
      return nb
    },
    maxTiles () {
      let max = this.modes[this.mode].files.length
      max = max > 200 ? 200 : max
      return max
    },
    maxTilesEven () {
      let max = this.maxTiles % 2 === 0 ? this.maxTiles : this.maxTiles - 1
      max = max > 200 ? 200 : max
      return max
    },
    maxGrid () {
      return Math.floor(Math.sqrt(this.maxTiles))
    },
    reinitBoardRequired () {
      let r = false
      if (
        (this.mode         !== this.settings.mode)         ||
        (this.nbRows       !== this.settings.nbRows)       ||
        (this.nbCols       !== this.settings.nbCols)       ||
        (this.fluidLayout  !== this.settings.fluidLayout)  ||
        (this.fluidNbTiles !== this.settings.fluidNbTiles)
      ) {
        r = true
      }
      return r
    },
    ...mapState({
      modes: 'modes',
      settings: 'settings',
      defaultSettings: 'defaultSettings',
    }),
    ...mapGetters([
      'checkLocalData',
      'checkLocalStats',
    ]),
  },

  methods: {
    updateLang (lang) {
      this.lang = lang
      this.$i18n.locale = lang
    },

    updateMode (mode) {
      this.mode = mode
      this.checkFluidNbTiles()
    },

    setNbRows (nb) {
      this.nbRows = nb
    },
    setNbCols (nb) {
      this.nbCols = nb
    },

    toggleNoAnimations () {
      this.noAnimations = ! this.noAnimations
    },

    toggleFluidLayout () {
      this.fluidLayout = ! this.fluidLayout
    },
    checkFluidNbTiles () {
      if (this.fluidNbTiles > this.maxTiles) {
        this.fluidNbTiles = this.maxTiles
      }
      if (this.fluidNbTiles % 2 === 1) {
        this.fluidNbTiles = this.fluidNbTiles - 1
      }
    },

    correctGrid () {
      if (this.nbRows > this.nbCols) {
        this.nbRows--
      } else {
        this.nbCols--
      }
      this.checkGrid()
    },
    checkGrid() {
      let total = this.nbRows * this.nbCols
      if (total % 2 == 1) {
        total--
      }
      if (total > this.maxTiles) {
        this.correctGrid()
      }
    },
    updateGrid () {
      this.checkGrid()
    },

    deleteData () {
      if (this.dataDeleted) {return}
      let msg = this.$t('settings-local-data-delete-all-confirm1')
      if (this.hasLocalStats) {
        msg = this.$t('settings-local-data-delete-all-confirm2')
      }
      if (confirm(msg)) {
        this.deleteLocalData()
        this.dataDeleted  = true
        this.lang         = this.defaultSettings.lang
        this.mode         = this.defaultSettings.mode
        this.nbRows       = this.defaultSettings.nbRows
        this.nbCols       = this.defaultSettings.nbCols
        this.fluidLayout  = this.defaultSettings.fluidLayout
        this.fluidNbTiles = this.defaultSettings.fluidNbTiles
      }
    },
    deletePlayerStats () {
      console.log('deletePlayerStats')
      if (this.dataDeleted || this.statsDeleted) {return}
      if (confirm(this.$t('settings-local-data-delete-stats-confirm'))) {
        this.deleteStats()
        this.statsDeleted = true
      }
    },

    toggleAutoPlay () {
      // if one mode (looping or not) is already running, we disable
      // the clicks on the other mode (user has to stop the other mode first)
      // if (this.autoPlayActive && this.autoPlayLoop && !loop) {return}
      // if (this.autoPlayActive && !this.autoPlayLoop && loop) {return}
      this.$emit('toggleAutoPlay')
      this.cancelAndClose()
    },
    toggleAutoPlayLoop () {
      this.$emit('toggleAutoPlayLoop')
    },

    confirmAndClose () {
      let tempSettings = {
        lang: this.lang,
        mode: this.mode,
        nbRows: this.nbRows,
        nbCols: this.nbCols,
        noAnimations: this.noAnimations,
        fluidLayout: this.fluidLayout,
        fluidNbTiles: this.fluidNbTiles,
      }
      this.saveTempSettings(tempSettings)
      this.closeModal()
    },
    cancelAndClose () {
      if (this.dataDeleted) {
        this.confirmAndClose()
        return
      }
      this.resetTempSettings()
      this.closeModal()
    },
    closeModal () {
      this.$emit('closeSettings', this.reinitBoardRequired)
    },
    ...mapActions([
      'deleteStats',
      'deleteLocalData',
    ]),
    ...mapMutations([
      'saveTempSettings',
      'resetTempSettings',
    ]),
  },
  beforeMount () {
    this.resetTempSettings()
    this.lang         = this.settings.lang || this.$i18n.locale || this.$i18n.fallbackLocale
    this.mode         = this.settings.mode
    this.nbRows       = this.settings.nbRows
    this.nbCols       = this.settings.nbCols
    this.noAnimations = this.settings.noAnimations
    this.fluidLayout  = this.settings.fluidLayout
    this.fluidNbTiles = this.settings.fluidNbTiles
  },
}
</script>

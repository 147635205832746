<template>
  <div
    class="tile"
    :class="tileClass"
    :style="tileCss"
  >
    <div
      class="side back"
      :style="backCss"
    >
      <span>?</span>
    </div>

    <div
      class="side front"
      @click="setFs"
      :style="frontCss"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SingleTile',
  props: {
    tile: Object,
    tileWidth: Number,
  },
  data () {return {
    flipped: false
  }},
  computed: {
    tileName () {
      return this.tile.name
    },
    tileUrl () {
      if (this.tileName) {
        return require('@/assets/tiles/' + this.tileName)
      } else {
        return require('@/assets/tiles/blank.jpg')
      }
    },
    tileClass () {
      let classes = []
      if (this.tile.status > 0) {
        classes.push('flipped')
      }
      if (this.tile.status === 2) {
        classes.push('found')
      }
      if (this.tile.status === -1) {
        classes.push('unflipped')
      }
      return classes
    },
    tileCss () {
      let css = {}
      if (this.settings.fluidLayout) {
        css['flex-basis'] = this.tileWidth + '%'
      }
      return css
    },
    frontCss () {
      let css = {
        'background-image': 'url(' + this.tileUrl + ')',
      }
      return css
    },
    backCss () {
      let css = {}
      if (this.settings.fluidLayout) {
        let boardWidth = Math.floor(document.getElementById('board').offsetWidth)
        let pxWidth = boardWidth * this.tileWidth / 100
        css['font-size'] = (pxWidth/1.5) + 'px'
      } else {
        css['font-size'] = 'min('+(65/this.settings.nbRows)+'vh, '+(65/this.settings.nbCols)+'vw)'
      }
      return css
    },
    ...mapState({
      settings: 'settings',
    }),
  },
  methods: {
    setFs () {
      this.$emit('setFs', this.tileName)
    },
  },
}
</script>

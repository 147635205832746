<template>
  <div class="overlay sidebar game-stats">
    <div
      class="overlay-bg"
      @click="closeModal"
    ></div>
    <div class="overlay-inner">
      <div class="overlay-content">

        <div
          class="stats-tabs"
          v-if="nbModes > 1"
        >
          <div
            v-for="(mode, key) in modes"
            :key="key"
            class="tab"
            :class="activeTab == key ? 'active' : ''"
            @click="setTab(key)"
            @shortkey="nextTab"
            v-shortkey="['tab']"
          >{{ mode[lang].name }}</div>
        </div>

        <div
          class="stats-tab stats-by-mode"
          v-for="(modeData, modeName) in statsByMode"
          :key="modeName"
        >
          <div
            class="stats-by-mode-inner"
            v-if="activeTab == modeName"
          >
            <div
              class="mode-title"
              :style="{'background-image': 'url('+modeImg(modeName)+')',}"
            >
              <h3><span>{{ $t('stats-stats') }}</span><br>{{ modeData.name }}</h3>
            </div>

            <div class="stats-by-mode-content">
              <template v-if="modeData.nbRuns">
                <p><strong>{{ modeData.nbRuns }} {{ $t('stats-games-played') }}</strong></p>
                <ul>
                  <li
                    v-for="(gdata, grid) in modeData.grids"
                    :key="grid"
                    :class="[
                      modeData.favGrid == grid ? 'is-fav' : '',
                      gdata.currentNb && settings.mode == modeName ? 'current-nb' : ''
                    ]"
                  >
                    <h4>
                      <strong>{{ $t('stats-grid-n-images', {n: parseInt(grid)}) }}</strong>
                        {{ $t('stats-grid-n-games-recorded', {n: gdata.games}) }}
                        <div class="fav">{{ $t('stats-grid-most-used') }}</div>
                    </h4>
                      <dl>
                        <dd>{{ $t('stats-grid-avg-tries') }}</dd>
                        <dt>{{ parseFloat((gdata.totalTries / gdata.games).toFixed(2)) }}</dt>

                        <dd>{{ $t('stats-grid-avg-time-img') }}</dd>
                        <dt>{{ (gdata.totalTime / (gdata.games * gdata.tiles) / 1000).toFixed(2) }} {{ $t('stats-grid-suffix-second') }}</dt>

                        <dd>{{ $t('stats-grid-avg-time-game') }}</dd>
                        <dt>{{ (gdata.totalTime / gdata.games / 1000).toFixed(2) }} {{ $t('stats-grid-suffix-second') }}</dt>

                        <dd>{{ $t('stats-grid-best-time') }}</dd>
                        <dt>{{ (gdata.bestTime / 1000).toFixed(2) }} {{ $t('stats-grid-suffix-second') }}</dt>

                        <dd>{{ $t('stats-grid-best-score') }}</dd>
                        <dt>{{ gdata.bestTries }} {{ $t('stats-grid-suffix-tries') }}</dt>
                      </dl>
                  </li>
                </ul>

              </template>
              <template v-if="! modeData.nbRuns">
                <p>{{ $t('stats-no-records-1a', {withThisCol: (settings.mode != modeName ? $t('stats-no-records-1b') : '')}) }}</p>
                <template v-if="settings.mode != modeName">
                  <p><strong>{{ $t('stats-no-records-2') }}</strong><br>
                    {{ $t('stats-no-records-3a') }}<a @click="openModal('settings')">{{ $t('stats-no-records-3b') }}</a>{{ $t('stats-no-records-3c') }}</p>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="overlay-actions">
        <div
          class="btn"
          @click="closeModal"
          @shortkey="closeModal"
          v-html="$t('panel-btn-close')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StatsView',
  data () {return {
    activeTab: '',
    allTabs: [],
  }},
  computed: {
    lang () {
      return this.$i18n.locale
    },
    nbModes () {
      return Object.keys(this.modes).length
    },
    currentNbTiles () {
      let nb
      if (this.settings.fluidLayout) {
        nb = this.settings.fluidNbTiles
      } else {
        nb = this.settings.nbCols * this.settings.nbRows
        if (nb % 2 === 1) {
          nb--
        }
      }
      return nb
    },
    statsByMode () {
      let r = {}
      // 1. list all modes
      for (const mode in this.modes) {
        let r2 = {
          name: this.modes[mode][this.lang].name,
          grids: {},
          favGrid: null,
        }
        let temp = this.stats.filter(x => x.mode == mode)

        // 2. for each mode:

        // number of games
        r2.nbRuns = temp.length

        // average length of games
        let totalTime = temp.reduce((x, y) => x + y.duration, 0)
        r2.averageTime = (totalTime / r2.nbRuns / 1000).toFixed(2)

        // average time per image
        let totalImgs = temp.reduce((x, y) => x + (y.tiles/2), 0)
        r2.averageImg = (totalTime / totalImgs / 1000).toFixed(2)

        // list all grids sizes, and their own stats
        temp.sort((a, b) => a.tiles < b.tiles)
        temp.forEach(t => {
          let grid = t.tiles
          // we want to keep those properties in descending order when listing them later on; therefore:
          if (grid < 10) {
            grid = '00' + grid
          } else if (grid < 100) {
            grid = '0' + grid
          }
          if (! r2.grids[grid]) {
            r2.grids[grid] = {
              tiles: t.tiles,
              games: 0,
              totalTime: 0,
              totalTries: 0,
              bestTime: Infinity,
              bestTries: Infinity,
              currentNb: false,
            }
          }
          r2.grids[grid].games++
          r2.grids[grid].totalTime += t.duration
          r2.grids[grid].totalTries += t.tries

          if (t.tiles == this.currentNbTiles) {
            r2.grids[grid].currentNb = true
          }

          if (r2.favGrid === null || r2.grids[grid].games > r2.grids[r2.favGrid].games) {
            r2.favGrid = grid
          }

          if (t.duration < r2.grids[grid].bestTime) {
            r2.grids[grid].bestTime = t.duration
          }
          if (t.tries < r2.grids[grid].bestTries) {
            r2.grids[grid].bestTries = t.tries
          }
        })

        r[mode] = r2
      }
      return r
    },
    ...mapState({
      modes: 'modes',
      stats: 'playerStats',
      settings: 'settings',
    }),
  },
  methods: {
    setTab (mode) {
      this.activeTab = mode
    },
    modeImg (mode) {
      let randImg = this.modes[mode].files[Math.floor(Math.random() * this.modes[mode].files.length)]
      return require('@/assets/tiles/' + randImg)
    },
    dateText (ts) {
      let date = new Date(ts)
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      return date.toLocaleDateString('en-US', options)
    },

    nextTab () {
      let tabIndex = this.allTabs.indexOf(this.activeTab)
      tabIndex++
      if (tabIndex >= this.allTabs.length) {
        tabIndex = 0
      }
      this.activeTab = this.allTabs[tabIndex]
    },

    openModal (name) {
      this.$emit('openModal', name)
    },
    closeModal () {
      this.$emit('closeModal')
    },
  },
  mounted () {
    this.activeTab = this.settings.mode
    for (const mode in this.modes) {
      this.allTabs.push(mode)
    }
  },
}
</script>
